import styled from "styled-components";
import layout from "../../../common/layout";

export const MainContainer = styled.div`
  padding: ${layout.designGlobalRules.desktop.padding};
  padding-top: 150px;
  padding-bottom: 150px;

  .portfolioBtn {
    border: 3px solid ${layout.colors.mainColor};
    padding: 10px 30px;
    border-radius: 50px;
  }

  .title {
    text-align: center;
    font-size: ${layout.designGlobalRules.desktop.subtitleFontSize};
  }

  .subtitle {
    padding-top: 10px;
    padding-bottom: 30px;
    letter-spacing: 1px;
    text-align: center;
    font-size: 18px;
    color: ${layout.colors.grayTextStrong};
    width: 65%;
    margin: auto;
  }

  @media only screen and (max-width: ${layout.screenResolutions.desktop}) {
    padding: ${layout.designGlobalRules.mobile.padding};
    .subtitle {
      font-size: 16px;
      padding-top: 10px;
      padding-bottom: 10px;
      width: 100%;
      text-align: justify;
    }

    .portfolioBtn {
      margin-top: 50px;
    }
  }
`;

export const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 102.1%;
  margin-left: -15px;
  margin-top: 50px;
  @media only screen and (max-width: ${layout.screenResolutions.desktop}) {
    width: 100%;
    margin-left: 0px;
  }
`;
