// SERVICES ICONS
import StairsIcon from "@mui/icons-material/Stairs";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import RouteIcon from "@mui/icons-material/Route";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import HubIcon from "@mui/icons-material/Hub";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import FilterTiltShiftIcon from "@mui/icons-material/FilterTiltShift";
import HandymanIcon from "@mui/icons-material/Handyman";
import EngineeringIcon from "@mui/icons-material/Engineering";
import FireExtinguisherIcon from "@mui/icons-material/FireExtinguisher";
import LockClockIcon from "@mui/icons-material/LockClock";
import ApartmentIcon from "@mui/icons-material/Apartment";
import TollIcon from "@mui/icons-material/Toll";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import OfflineBoltIcon from "@mui/icons-material/OfflineBolt";
import CarpenterIcon from "@mui/icons-material/Carpenter";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import SolarPowerIcon from "@mui/icons-material/SolarPower";

// GALERY RESOUCES
import METT1R1 from "../../assets/website/services/metalmecanicos/T1/METT1R1.jpeg";
import METT1R2 from "../../assets/website/services/metalmecanicos/T1/METT1R2.jpeg";
import METT1R3 from "../../assets/website/services/metalmecanicos/T1/METT1R3.jpeg";
import METT2R1 from "../../assets/website/services/metalmecanicos/T2/METT2R1.jpeg";
import METT2R2 from "../../assets/website/services/metalmecanicos/T2/METT2R2.jpeg";
import METT2R3 from "../../assets/website/services/metalmecanicos/T2/METT2R3.jpeg";
import METT2R4 from "../../assets/website/services/metalmecanicos/T2/METT2R4.jpeg";
import METT2R5 from "../../assets/website/services/metalmecanicos/T2/METT2R5.jpeg";
import METT2R6 from "../../assets/website/services/metalmecanicos/T2/METT2R6.jpeg";
import METT2R7 from "../../assets/website/services/metalmecanicos/T2/METT2R7.jpeg";
import METT3R1 from "../../assets/website/services/metalmecanicos/T3/METT3R1.jpeg";
import METT3R2 from "../../assets/website/services/metalmecanicos/T3/METT3R2.jpeg";
import METT3R3 from "../../assets/website/services/metalmecanicos/T3/METT3R3.jpeg";
import ELECT1R1 from "../../assets/website/services/electromecanicos/T1/ELECT1R1.jpeg";
import ELECT1R2 from "../../assets/website/services/electromecanicos/T1/ELECT1R2.jpeg";
import ELECT1R3 from "../../assets/website/services/electromecanicos/T1/ELECT1R3.jpeg";
import ELECT1R4 from "../../assets/website/services/electromecanicos/T1/ELECT1R4.jpeg";
import ELECT1R5 from "../../assets/website/services/electromecanicos/T1/ELECT1R5.jpeg";
import ELECT1R6 from "../../assets/website/services/electromecanicos/T1/ELECT1R6.jpeg";
import ELECT1R7 from "../../assets/website/services/electromecanicos/T1/ELECT1R7.jpeg";
import ELECT1R8 from "../../assets/website/services/electromecanicos/T1/ELECT1R8.jpeg";
import ELECT1R9 from "../../assets/website/services/electromecanicos/T1/ELECT1R9.jpeg";
import ELECT2R1 from "../../assets/website/services/electromecanicos/T2/ELECT2R1.jpeg";
import ELECT2R2 from "../../assets/website/services/electromecanicos/T2/ELECT2R2.jpeg";
import ET1R1 from "../../assets/website/services/energiasRenovables/T1/ET1R1.jpeg";
import ET1R2 from "../../assets/website/services/energiasRenovables/T1/ET1R2.jpeg";
import ET1R3 from "../../assets/website/services/energiasRenovables/T1/ET1R3.jpeg";
import ET1R4 from "../../assets/website/services/energiasRenovables/T1/ET1R4.jpeg";
import ET1R5 from "../../assets/website/services/energiasRenovables/T1/ET1R5.jpeg";
import ET1R6 from "../../assets/website/services/energiasRenovables/T1/ET1R6.jpeg";
import ET1R7 from "../../assets/website/services/energiasRenovables/T1/ET1R7.jpeg";
import ET1R8 from "../../assets/website/services/energiasRenovables/T1/ET1R8.mp4";
import ET1R9 from "../../assets/website/services/energiasRenovables/T1/ET1R9.mp4";
import ET1R10 from "../../assets/website/services/energiasRenovables/T1/ET1R10.mp4";

const languageContent = {
  home: {
    title: {
      spanish: "Parav Industrial S.A. de C.V.",
      english: "Parav Industrial S.A. de C.V.",
    },
    description: {
      spanish: `Somos una empresa comprometida con nuestros clientes, ofrecemos servicios industriales, electromecánicos, suministro de equipos, partes y refacciones en México.`,
      english: `We are a solid company, committed to our customers, offering : industrial services, electromechanical services, supplies of self-safety equipment and spare parts around México.`,
    },
    button: {
      spanish: `CONTACTAR`,
      english: `CONTACT NOW`,
    },
  },
  aboutUs: {
    title: {
      spanish: "Nosotros",
      english: "About us",
    },
    description: {
      spanish: "PARAV se distingue por experiencia y sólides.",
      english: "",
    },
    options: {
      aboutUs: {
        btn: {
          spanish: "Nosotros",
          english: "About us",
        },
        description: {
          spanish:
            "Somos una empresa consolidada con mas de 15 años de experiencia ofreciendo servicios a los mejores del ramo industrial automotriz siguiendo los más altos estandares de calidad y seguridad.",
          english: "",
        },
      },
      vision: {
        btn: {
          spanish: "Visión",
          english: "Vision",
        },
        description: {
          spanish:
            "Ser una empresa líder en el ramo industrial y fuera de el con altos estándares de calidad y valores.",
          english: "",
        },
      },
      history: {
        btn: {
          spanish: "Historia",
          english: "History",
        },
        description: {
          spanish:
            "Parav nace en hermosillo sonora , como un proyecto enfocado en brindar soluciones de alta calidad en la entidad. permitiendonos la posibilidad de trabajar con clientes potenciales y ofrecer nuestros servicios a nivel nacional.",
          english: "",
        },
      },
    },
  },
  services: {
    btnView: {
      spanish: "Ver Servicios",
      english: "View Services",
    },
    title: {
      spanish: "Nuestros Servicios",
      english: "Our Services",
    },
    description: {
      spanish:
        "Brindamos soluciones tanto a grandes empresas como a pequeñas empresas y trabajadores autónomos, distinguiéndonos por la mejor atención, organización y calidad en cada proyecto.",
      english: "",
    },
    btnQuote: {
      spanish: "Cotizar",
      english: "Get Quote",
    },
    typeOfServices: [
      {
        spanish: "Metalmecánicos",
        english: "",
      },
      {
        spanish: "Electromecánicos",
        english: "",
      },
      {
        spanish: "Maquinados",
        english: "",
      },
      {
        spanish: "Herramientas y equipos",
        english: "",
      },
      {
        spanish: "Capacitaciones",
        english: "",
      },
      {
        spanish: "Energias Renovables",
        english: "",
      },
    ],

    options: {
      Metalmecánicos: [
        {
          icon: StairsIcon,
          title: {
            spanish: "Fabricación e instalación de Plataformas.",
            english: "",
          },
          description: {
            spanish:
              "Fabricación de mezanine, plataformas, escaleras, racks, etc.",
            english: "",
          },
          quote: {
            subject: {
              spanish:
                "Solicito cotización para el servicio : Fabricación e instalación de Plataformas",
              english: "",
            },
            message: {
              spanish: `Hola buen día! Me interesa recibir una cotización de su servicio para la siguiente empresa : 

      empresa : [RAZON SOCIAL DE LA EMPRESA]
      ubicación: [CIUDAD, ESTADO]`,
              english: "",
            },
          },
        },
        {
          icon: WarehouseIcon,
          title: {
            spanish: "Fabricación e instalación de Estructuras",
            english: "",
          },
          description: {
            spanish: "Diseño y montaje de estructuras.",
            english: "",
          },
          quote: {
            subject: {
              spanish:
                "Solicito cotización para el servicio : Fabricación e instalación de Estructuras",
              english: "",
            },
            message: {
              spanish: `Hola buen día! Me interesa recibir una cotización de su servicio para la siguiente empresa : 

      empresa : [RAZON SOCIAL DE LA EMPRESA]
      ubicación: [CIUDAD, ESTADO]`,
              english: "",
            },
          },
        },
        {
          icon: HealthAndSafetyIcon,
          title: {
            spanish: "Fabricación e instalación de Guardas de Seguridad",
            english: "",
          },
          description: {
            spanish: "Fabricación y montaje de guardas de seguridad.",
            english: "",
          },
          quote: {
            subject: {
              spanish:
                "Solicito cotización para el servicio : Fabricación e instalación de Guardas de Seguridad",
              english: "",
            },
            message: {
              spanish: `Hola buen día! Me interesa recibir una cotización de su servicio para la siguiente empresa : 

      empresa : [RAZON SOCIAL DE LA EMPRESA]
      ubicación: [CIUDAD, ESTADO]`,
              english: "",
            },
          },
        },
      ],
      Electromecánicos: [
        {
          icon: RouteIcon,
          title: {
            spanish: "Colocación y ruteo de tuberías.",
            english: "",
          },
          description: {
            spanish:
              "Nos encargamos de analizar, desarrollar y dar mantenimiento a los sistemas y dispositivos relacionados a la electromecánica, Así como realizar las revisiones respectivas de los equipos y de máquinas.",
            english: "",
          },
          quote: {
            subject: {
              spanish:
                "Solicito cotización para el servicio : Colocación y ruteo de tuberías",
              english: "",
            },
            message: {
              spanish: `Hola buen día! Me interesa recibir una cotización de su servicio para la siguiente empresa : 

      empresa : [RAZON SOCIAL DE LA EMPRESA]
      ubicación: [CIUDAD, ESTADO]`,
              english: "",
            },
          },
        },
        {
          icon: ElectricBoltIcon,
          title: {
            spanish: "Colocación y ruteo de líneas eléctricas.",
            english: "",
          },
          description: {
            spanish:
              "Nos encargamos de analizar, desarrollar y dar mantenimiento a los sistemas y dispositivos relacionados a la electromecánica, Así como realizar las revisiones respectivas de los equipos y de máquinas.",
            english: "",
          },
          quote: {
            subject: {
              spanish:
                "Solicito cotización para el servicio : Colocación y ruteo de líneas eléctricas",
              english: "",
            },
            message: {
              spanish: `Hola buen día! Me interesa recibir una cotización de su servicio para la siguiente empresa : 

      empresa : [RAZON SOCIAL DE LA EMPRESA]
      ubicación: [CIUDAD, ESTADO]`,
              english: "",
            },
          },
        },
        {
          icon: MiscellaneousServicesIcon,
          title: {
            spanish: "Mantenimiento motores, compresores, bombas, etc",
            english: "",
          },
          description: {
            spanish:
              "Nos encargamos de analizar, desarrollar y dar mantenimiento a los sistemas y dispositivos relacionados a la electromecánica, Así como realizar las revisiones respectivas de los equipos y de máquinas.",
            english: "",
          },
          quote: {
            subject: {
              spanish:
                "Solicito cotización para el servicio : Mantenimiento motores, compresores, bombas, etc",
              english: "",
            },
            message: {
              spanish: `Hola buen día! Me interesa recibir una cotización de su servicio para la siguiente empresa : 

      empresa : [RAZON SOCIAL DE LA EMPRESA]
      ubicación: [CIUDAD, ESTADO]`,
              english: "",
            },
          },
        },
        {
          icon: PrecisionManufacturingIcon,
          title: {
            spanish: "Instalación y mantenimiento de robot de pintura.",
            english: "",
          },
          description: {
            spanish:
              "Nos encargamos de analizar, desarrollar y dar mantenimiento a los sistemas y dispositivos relacionados a la electromecánica, Así como realizar las revisiones respectivas de los equipos y de máquinas.",
            english: "",
          },
          quote: {
            subject: {
              spanish:
                "Solicito cotización para el servicio : Instalación y mantenimiento de robot de pintura",
              english: "",
            },
            message: {
              spanish: `Hola buen día! Me interesa recibir una cotización de su servicio para la siguiente empresa : 

      empresa : [RAZON SOCIAL DE LA EMPRESA]
      ubicación: [CIUDAD, ESTADO]`,
              english: "",
            },
          },
        },
      ],
      Maquinados: [
        {
          icon: HubIcon,
          title: {
            spanish: "CNC",
            english: "CNC",
          },
          description: {
            spanish: "Creación, instalación y configuración de equipo CNC.",
            english: "",
          },
          quote: {
            subject: {
              spanish: "Solicito cotización para el servicio : CNC",
              english: "",
            },
            message: {
              spanish: `Hola buen día! Me interesa recibir una cotización de su servicio para la siguiente empresa : 

      empresa : [RAZON SOCIAL DE LA EMPRESA]
      ubicación: [CIUDAD, ESTADO]`,
              english: "",
            },
          },
        },
        {
          icon: GroupWorkIcon,
          title: {
            spanish: "Fresas",
            english: "",
          },
          description: {
            spanish: "Instalación y mantenimiento de Fresas.",
            english: "",
          },
          quote: {
            subject: {
              spanish: "Solicito cotización para el servicio : Fresas",
              english: "",
            },
            message: {
              spanish: `Hola buen día! Me interesa recibir una cotización de su servicio para la siguiente empresa : 

      empresa : [RAZON SOCIAL DE LA EMPRESA]
      ubicación: [CIUDAD, ESTADO]`,
              english: "",
            },
          },
        },
        {
          icon: FilterTiltShiftIcon,
          title: {
            spanish: "Torno",
            english: "",
          },
          description: {
            spanish: "Instalación de maquinas para Torno.",
            english: "",
          },
          quote: {
            subject: {
              spanish: "Solicito cotización para el servicio : Torno",
              english: "",
            },
            message: {
              spanish: `Hola buen día! Me interesa recibir una cotización de su servicio para la siguiente empresa : 

      empresa : [RAZON SOCIAL DE LA EMPRESA]
      ubicación: [CIUDAD, ESTADO]`,
              english: "",
            },
          },
        },
      ],
      Herramientas: [
        {
          icon: HandymanIcon,
          title: {
            spanish: "Herramientas",
            english: "Tools",
          },
          description: {
            spanish:
              "Venta de Herramientas Mecánicas, Neumáticas, Eléctricas, etc.",
            english: "",
          },
          quote: {
            subject: {
              spanish: "Solicito cotización para el servicio : Herramientas",
              english: "",
            },
            message: {
              spanish: `Hola buen día! Me interesa recibir una cotización de su servicio para la siguiente empresa : 

      empresa : [RAZON SOCIAL DE LA EMPRESA]
      ubicación: [CIUDAD, ESTADO]`,
              english: "",
            },
          },
        },
        {
          icon: EngineeringIcon,
          title: {
            spanish: "EPP",
            english: "",
          },
          description: {
            spanish:
              "Venta de equipo de protección personal como : Lentes, Zapatos, Guantes, Cascos, Mascaras,etc.",
            english: "",
          },
          quote: {
            subject: {
              spanish: "Solicito cotización para el servicio : EPP",
              english: "",
            },
            message: {
              spanish: `Hola buen día! Me interesa recibir una cotización de su servicio para la siguiente empresa : 

      empresa : [RAZON SOCIAL DE LA EMPRESA]
      ubicación: [CIUDAD, ESTADO]`,
              english: "",
            },
          },
        },
        {
          icon: FireExtinguisherIcon,
          title: {
            spanish: "Equipos",
            english: "",
          },
          description: {
            spanish:
              "Venta de Equipos como : Válvulas, Compresores, bombas,etc.",
            english: "",
          },
          quote: {
            subject: {
              spanish: "Solicito cotización para el servicio : Equipos",
              english: "",
            },
            message: {
              spanish: `Hola buen día! Me interesa recibir una cotización de su servicio para la siguiente empresa : 

      empresa : [RAZON SOCIAL DE LA EMPRESA]
      ubicación: [CIUDAD, ESTADO]`,
              english: "",
            },
          },
        },
      ],
      Capacitaciones: [
        {
          icon: LockClockIcon,
          title: {
            spanish: "Bloqueo y candadeo de energias - ECPL.",
            english: "",
          },
          description: {
            spanish: "Bloqueo y candadeo de energias - ECPL.",
            english: "",
          },
          quote: {
            subject: {
              spanish:
                "Solicito cotización para el servicio : Bloqueo y candadeo de energias - ECPL",
              english: "",
            },
            message: {
              spanish: `Hola buen día! Me interesa recibir una cotización de su servicio para la siguiente empresa : 

      empresa : [RAZON SOCIAL DE LA EMPRESA]
      ubicación: [CIUDAD, ESTADO]`,
              english: "",
            },
          },
        },
        {
          icon: ApartmentIcon,
          title: {
            spanish: "Trabajos en alturas.",
            english: "",
          },
          description: {
            spanish: "Trabajos en alturas.",
            english: "",
          },
          quote: {
            subject: {
              spanish:
                "Solicito cotización para el servicio : Trabajos en alturas",
              english: "",
            },
            message: {
              spanish: `Hola buen día! Me interesa recibir una cotización de su servicio para la siguiente empresa : 

      empresa : [RAZON SOCIAL DE LA EMPRESA]
      ubicación: [CIUDAD, ESTADO]`,
              english: "",
            },
          },
        },
        {
          icon: TollIcon,
          title: {
            spanish: "Espacios confinados.",
            english: "",
          },
          description: {
            spanish: "Espacios confinados.",
            english: "",
          },
          quote: {
            subject: {
              spanish:
                "Solicito cotización para el servicio : Espacios confinados",
              english: "",
            },
            message: {
              spanish: `Hola buen día! Me interesa recibir una cotización de su servicio para la siguiente empresa : 

      empresa : [RAZON SOCIAL DE LA EMPRESA]
      ubicación: [CIUDAD, ESTADO]`,
              english: "",
            },
          },
        },
        {
          icon: ReportProblemIcon,
          title: {
            spanish: "Materiales peligorsos - HAZMAT.",
            english: "",
          },
          description: {
            spanish: "Materiales peligorsos - HAZMAT.",
            english: "",
          },
          quote: {
            subject: {
              spanish:
                "Solicito cotización para el servicio : Materiales peligorsos - HAZMAT",
              english: "",
            },
            message: {
              spanish: `Hola buen día! Me interesa recibir una cotización de su servicio para la siguiente empresa : 

      empresa : [RAZON SOCIAL DE LA EMPRESA]
      ubicación: [CIUDAD, ESTADO]`,
              english: "",
            },
          },
        },
        {
          icon: WarehouseIcon,
          title: {
            spanish: "Operación de montacargas.",
            english: "",
          },
          description: {
            spanish: "Operación de montacargas.",
            english: "",
          },
          quote: {
            subject: {
              spanish:
                "Solicito cotización para el servicio : Operación de montacargas",
              english: "",
            },
            message: {
              spanish: `Hola buen día! Me interesa recibir una cotización de su servicio para la siguiente empresa : 

      empresa : [RAZON SOCIAL DE LA EMPRESA]
      ubicación: [CIUDAD, ESTADO]`,
              english: "",
            },
          },
        },
        {
          icon: MoveUpIcon,
          title: {
            spanish: "Operación de plataformas de elevación.",
            english: "",
          },
          description: {
            spanish: "Operación de plataformas de elevación.",
            english: "",
          },
          quote: {
            subject: {
              spanish:
                "Solicito cotización para el servicio : Operación de plataformas de elevación",
              english: "",
            },
            message: {
              spanish: `Hola buen día! Me interesa recibir una cotización de su servicio para la siguiente empresa : 

      empresa : [RAZON SOCIAL DE LA EMPRESA]
      ubicación: [CIUDAD, ESTADO]`,
              english: "",
            },
          },
        },
        {
          icon: HomeRepairServiceIcon,
          title: {
            spanish: "Trabajos de corte y soldadura.",
            english: "",
          },
          description: {
            spanish: "Trabajos de corte y soldadura.",
            english: "",
          },
          quote: {
            subject: {
              spanish:
                "Solicito cotización para el servicio : Trabajos de corte y soldadura",
              english: "",
            },
            message: {
              spanish: `Hola buen día! Me interesa recibir una cotización de su servicio para la siguiente empresa : 

      empresa : [RAZON SOCIAL DE LA EMPRESA]
      ubicación: [CIUDAD, ESTADO]`,
              english: "",
            },
          },
        },
        {
          icon: OfflineBoltIcon,
          title: {
            spanish: "Trabajos seguros con electricidad.",
            english: "",
          },
          description: {
            spanish: "Trabajos seguros con electricidad.",
            english: "",
          },
          quote: {
            subject: {
              spanish:
                "Solicito cotización para el servicio : Trabajos seguros con electricidad",
              english: "",
            },
            message: {
              spanish: `Hola buen día! Me interesa recibir una cotización de su servicio para la siguiente empresa : 

      empresa : [RAZON SOCIAL DE LA EMPRESA]
      ubicación: [CIUDAD, ESTADO]`,
              english: "",
            },
          },
        },
        {
          icon: CarpenterIcon,
          title: {
            spanish: "Seguridad con herramientas manuales y motorizadas.",
            english: "",
          },
          description: {
            spanish: "Seguridad con herramientas manuales y motorizadas.",
            english: "",
          },
          quote: {
            subject: {
              spanish:
                "Solicito cotización para el servicio : Seguridad con herramientas manuales y motorizadas",
              english: "",
            },
            message: {
              spanish: `Hola buen día! Me interesa recibir una cotización de su servicio para la siguiente empresa : 

      empresa : [RAZON SOCIAL DE LA EMPRESA]
      ubicación: [CIUDAD, ESTADO]`,
              english: "",
            },
          },
        },
        {
          icon: MedicalServicesIcon,
          title: {
            spanish: "Primeros auxilios.",
            english: "",
          },
          description: {
            spanish: "Primeros auxilios.",
            english: "",
          },
          quote: {
            subject: {
              spanish:
                "Solicito cotización para el servicio : Primeros auxilios",
              english: "",
            },
            message: {
              spanish: `Hola buen día! Me interesa recibir una cotización de su servicio para la siguiente empresa : 

      empresa : [RAZON SOCIAL DE LA EMPRESA]
      ubicación: [CIUDAD, ESTADO]`,
              english: "",
            },
          },
        },
      ],
      Energias: [
        {
          icon: SolarPowerIcon,
          title: {
            spanish: "Paneles Solares.",
            english: "",
          },
          description: {
            spanish:
              "Servicio e instalación de paneles solares industriales o recidenciales.",
            english: "",
          },
          quote: {
            subject: {
              spanish:
                "Solicito cotización para el servicio : Servicio e instalación de paneles solares industriales o recidenciales",
              english: "",
            },
            message: {
              spanish: `Hola buen día! Me interesa recibir una cotización de su servicio para la siguiente empresa : 

      empresa : [RAZON SOCIAL DE LA EMPRESA]
      ubicación: [CIUDAD, ESTADO]`,
              english: "",
            },
          },
        },
      ],
    },
  },
  galery: {
    btnView: {
      spanish: "Ver Galería de Trabajos",
      english: "View Galery",
    },
    title: {
      spanish: "Nuestra Galería de Trabajos",
      english: "Our Work Galery",
    },
    description: {
      spanish:
        "Estos son algunos de nuestros trabajos realizados recientemente.",
      english: "",
    },
    options: {
      Metalmecánicos: [
        [
          {
            type: "img",
            img: METT1R1,
            description: {
              spanish: "Desmantelamiento de tunel plastico",
              english: "",
            },
          },
          // {
          //   type: "video",
          //   video: PanelVideo,
          //   img: Metal_racks,
          //   description: {
          //     spanish: "Diseño y montaje de estructuras.",
          //     english: "",
          //   },
          // },
          {
            type: "img",
            img: METT1R2,
            description: {
              spanish: "Desmantelamiento de tunel plastico.",
              english: "",
            },
          },
          {
            type: "img",
            img: METT1R3,
            description: {
              spanish: "Desmantelamiento de tunel plastico.",
              english: "",
            },
          },
        ],
        [
          {
            type: "img",
            img: METT2R1,
            description: {
              spanish:
                "Fabricación e instalación de plataforma para estación de trabajo",
              english: "",
            },
          },
          {
            type: "img",
            img: METT2R2,
            description: {
              spanish:
                "Fabricación e instalación de plataforma para estación de trabajo",
              english: "",
            },
          },
          {
            type: "img",
            img: METT2R3,
            description: {
              spanish:
                "Fabricación e instalación de plataforma para estación de trabajo",
              english: "",
            },
          },
          {
            type: "img",
            img: METT2R4,
            description: {
              spanish:
                "Fabricación e instalación de plataforma para estación de trabajo",
              english: "",
            },
          },
          {
            type: "img",
            img: METT2R5,
            description: {
              spanish:
                "Fabricación e instalación de plataforma para estación de trabajo",
              english: "",
            },
          },
          {
            type: "img",
            img: METT2R6,
            description: {
              spanish:
                "Fabricación e instalación de plataforma para estación de trabajo",
              english: "",
            },
          },
          {
            type: "img",
            img: METT2R7,
            description: {
              spanish:
                "Fabricación e instalación de plataforma para estación de trabajo",
              english: "",
            },
          },
        ],
        [
          {
            type: "img",
            img: METT3R1,
            description: {
              spanish:
                "Fabricación e instalación de rack de servicios en planta Nortek",
              english: "",
            },
          },
          {
            type: "img",
            img: METT3R2,
            description: {
              spanish:
                "Fabricación e instalación de rack de servicios en planta Nortek",
              english: "",
            },
          },
          {
            type: "img",
            img: METT3R3,
            description: {
              spanish:
                "Fabricación e instalación de rack de servicios en planta Nortek",
              english: "",
            },
          },
        ],
      ],
      Electromecánicos: [
        [
          {
            type: "img",
            img: ELECT1R1,
            description: {
              spanish:
                "conexión de aire y electricidad en linea sl3 planta Nortek",
              english: "",
            },
          },
          {
            type: "img",
            img: ELECT1R2,
            description: {
              spanish:
                "conexión de aire y electricidad en linea sl3 planta Nortek",
              english: "",
            },
          },
          {
            type: "img",
            img: ELECT1R3,
            description: {
              spanish:
                "conexión de aire y electricidad en linea sl3 planta Nortek",
              english: "",
            },
          },
          {
            type: "img",
            img: ELECT1R4,
            description: {
              spanish:
                "conexión de aire y electricidad en linea sl3 planta Nortek",
              english: "",
            },
          },
          {
            type: "img",
            img: ELECT1R5,
            description: {
              spanish:
                "conexión de aire y electricidad en linea sl3 planta Nortek",
              english: "",
            },
          },
          {
            type: "img",
            img: ELECT1R6,
            description: {
              spanish:
                "conexión de aire y electricidad en linea sl3 planta Nortek",
              english: "",
            },
          },
          {
            type: "img",
            img: ELECT1R7,
            description: {
              spanish:
                "conexión de aire y electricidad en linea sl3 planta Nortek",
              english: "",
            },
          },
          {
            type: "img",
            img: ELECT1R8,
            description: {
              spanish:
                "conexión de aire y electricidad en linea sl3 planta Nortek",
              english: "",
            },
          },
          {
            type: "img",
            img: ELECT1R9,
            description: {
              spanish:
                "conexión de aire y electricidad en linea sl3 planta Nortek",
              english: "",
            },
          },
        ],
        [
          {
            type: "img",
            img: ELECT2R1,
            description: {
              spanish: "Reubicación de clinchadora planta Nortek",
              english: "",
            },
          },
          {
            type: "img",
            img: ELECT2R2,
            description: {
              spanish: "Reubicación de clinchadora planta Nortek",
              english: "",
            },
          },
        ],
      ],
      Maquinados: [],
      Herramientas: [],
      Capacitaciones: [],
      Energias: [
        [
          {
            type: "img",
            img: ET1R1,
            description: {
              spanish: "Instalación de paneles solares en zona residencial.",
              english: "",
            },
          },
          {
            type: "img",
            img: ET1R2,
            description: {
              spanish: "Instalación de paneles solares en zona residencial.",
              english: "",
            },
          },
          {
            type: "img",
            img: ET1R3,
            description: {
              spanish: "Instalación de paneles solares en zona residencial.",
              english: "",
            },
          },
          {
            type: "img",
            img: ET1R4,
            description: {
              spanish: "Instalación de paneles solares en zona residencial.",
              english: "",
            },
          },
          {
            type: "img",
            img: ET1R5,
            description: {
              spanish: "Instalación de paneles solares en zona residencial.",
              english: "",
            },
          },
          {
            type: "img",
            img: ET1R6,
            description: {
              spanish: "Instalación de paneles solares en zona residencial.",
              english: "",
            },
          },
          {
            type: "img",
            img: ET1R7,
            description: {
              spanish: "Instalación de paneles solares en zona residencial.",
              english: "",
            },
          },
          {
            type: "video",
            video: ET1R8,
            img: ET1R1,
            description: {
              spanish: "Instalación de paneles solares en zona residencial.",
              english: "",
            },
          },
          {
            type: "video",
            video: ET1R9,
            img: ET1R1,
            description: {
              spanish: "Instalación de paneles solares en zona residencial.",
              english: "",
            },
          },
          {
            type: "video",
            video: ET1R10,
            img: ET1R1,
            description: {
              spanish: "Instalación de paneles solares en zona residencial.",
              english: "",
            },
          },
        ],
      ],
    },
  },
  contact: {
    title: {
      spanish: "Contacto",
      english: "Get in Touch",
    },
    subtitle: {
      spanish: "Empecemos un nuevo proyecto juntos !",
      english: "Ready to Get Started",
    },
    description: {
      spanish:
        "Nuestro equipo especializado está a su dispocición, listo para atender todas sus dudas.",
      english: "",
    },
    cards: {
      contact: {
        title: {
          spanish: "Contacto",
          english: "Contact",
        },
        phone: "6622407870",
        email: "parav@contacto.mx",
      },
      address: {
        title: {
          spanish: "Dirección",
          english: "Address",
        },
        street: "Hermosillo Sonora",
        country: "México",
      },
      schedule: {
        title: {
          spanish: "Horarios",
          english: "Schedule",
        },
        days: {
          spanish: "De lunes a Viernes",
          english: "Monday to Friday",
        },
        time: {
          spanish: "Horario de : 9:00 AM - 5:30 PM",
          english: "Office time: 9:00 AM - 5:30 PM",
        },
      },
    },
    form: {
      name: {
        spanish: "Nombre",
        english: "Name",
      },
      email: {
        spanish: "Correo",
        english: "Email",
      },
      phone: {
        spanish: "Número de teléfono",
        english: "Phone",
      },
      subject: {
        spanish: "Asunto",
        english: "Subject",
      },
      message: {
        spanish: "Mensaje aqui...",
        english: "Your message here...",
      },
      submitBtn: {
        before: {
          spanish: "Enviar Correo",
          english: "Send Email",
        },
        sending: {
          spanish: "Enviando...",
          english: "Sending...",
        },
        sent: {
          spanish: "Correo enviado !",
          english: "Email sent !",
        },
        error: {
          spanish: "No se pudo enviar correo, intente más tarde!",
          english: "Email has not been sent, please try again !",
        },
      },
    },
  },
  footer: {
    description: {
      spanish: "Parav SA de CV, soluciones industriales y resindeciales.",
      english: "",
    },
    designed: {
      year: `© ${new Date().getFullYear().toString()} Edmoons`,
      text: {
        spanish: "Creado y desarrollado por ",
        english: "",
      },
    },
    services: {
      title: {
        spanish: "Servicios ",
        english: "Services",
      },
      typeOfServices: [
        {
          spanish: "Metalmecánicos",
          english: "",
        },
        {
          spanish: "Electromecánicos",
          english: "",
        },
        {
          spanish: "Maquinados",
          english: "",
        },
        {
          spanish: "Herramientas y equipos",
          english: "",
        },
        {
          spanish: "Capacitaciones",
          english: "",
        },
        {
          spanish: "Energias Renovables",
          english: "",
        },
      ],
    },
  },
};

export default languageContent;
