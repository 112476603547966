import { Button } from "@mui/material";
import { useEffect } from "react";
import languageContent from "../../../../common/website/languageAndContent";
import { useLanguage } from "../../../../stateManagement/website/context/language";
import { MainContainer } from "./tabs.style";

interface localProps {
  currentService: string;
  setCurrentService: Function;
}

const Tabs = ({ currentService, setCurrentService }: localProps) => {
  const { currentLanguage } = useLanguage();
  useEffect(() => {
    currentService === "" &&
      setCurrentService(
        languageContent.services.typeOfServices[0][currentLanguage]
      );
  }, [currentService, setCurrentService]);

  const handleOption = (itemName: string) => {
    setCurrentService(itemName);
  };

  const servicesList = languageContent.services.typeOfServices.map(
    (item, index) => (
      <div style={{ flex: 1 }} key={index}>
        <Button
          className="item"
          onClick={() => {
            handleOption(item[currentLanguage]);
          }}
        >
          <p
            className={
              currentService === item[currentLanguage]
                ? "text selectedText"
                : "text"
            }
          >
            {item[currentLanguage].toUpperCase()}
          </p>
        </Button>
        <div
          className={
            currentService === item[currentLanguage]
              ? "line selectedLine"
              : "line"
          }
        ></div>
      </div>
    )
  );

  return <MainContainer>{servicesList}</MainContainer>;
};

export default Tabs;
