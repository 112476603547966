import ButtonBase from "@mui/material/ButtonBase";
import { MainContainer } from "./item.style";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import { useLanguage } from "../../../../../stateManagement/website/context/language";
import { useContact } from "../../../../../stateManagement/website/context/contact";

interface localPorps {
  item: any;
  index: any;
  quoteBtnText: string;
}
const ServiceItem = ({ item, index, quoteBtnText }: localPorps) => {
  const { currentLanguage } = useLanguage();
  const { handleData } = useContact();

  const handleContextContact = (quote: any) => {
    handleData({ name: "subject", value: quote.subject[currentLanguage] });
    setTimeout(() => {
      handleData({ name: "message", value: quote.message[currentLanguage] });
    }, 500);
  };

  const buttonComponent = (text: string) => (
    <ButtonBase
      style={{
        borderRadius: 10,
        zIndex: "0!important",
        height: "auto",
      }}
    >
      <a
        href="#contact"
        className="quoteBtn"
        onClick={() => {
          handleContextContact(item.quote);
        }}
      >
        {text.toUpperCase()}
      </a>
    </ButtonBase>
  );

  const Icon = item.icon || HealthAndSafetyIcon;

  return (
    <MainContainer key={index}>
      <div className="withPadding">
        <div className="card">
          <div style={{ display: "flex" }}>
            <div className="iconCircleContainer">
              <Icon className="icon" />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <h2>{item.title && item.title[currentLanguage]}</h2>
            <p>{item.description && item.description[currentLanguage]}</p>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            {buttonComponent(quoteBtnText)}
          </div>
        </div>
      </div>
    </MainContainer>
  );
};
export default ServiceItem;
