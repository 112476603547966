import { useState, useEffect } from "react";
import languageContent from "../../../../common/website/languageAndContent";

const useServiceCategory = (serviceName: string, listOption: string) => {
  const { services, galery }: any = languageContent;
  const [elements, setElements] = useState([]);

  useEffect(() => {
    switch (serviceName) {
      case "Metalmecánicos":
        setElements(
          listOption === "services"
            ? services.options.Metalmecánicos
            : galery.options.Metalmecánicos
        );
        break;
      case "Electromecánicos":
        setElements(
          listOption === "services"
            ? services.options.Electromecánicos
            : galery.options.Electromecánicos
        );
        break;
      case "Maquinados":
        setElements(
          listOption === "services"
            ? services.options.Maquinados
            : galery.options.Maquinados
        );
        break;
      case "Herramientas y equipos":
        setElements(
          listOption === "services"
            ? services.options.Herramientas
            : galery.options.Herramientas
        );
        break;

      case "Capacitaciones":
        setElements(
          listOption === "services"
            ? services.options.Capacitaciones
            : galery.options.Capacitaciones
        );
        break;
      case "Energias Renovables":
        setElements(
          listOption === "services"
            ? services.options.Energias
            : galery.options.Energias
        );
        break;

      default:
        break;
    }
  }, [serviceName, listOption]);

  return elements;
};

export default useServiceCategory;
