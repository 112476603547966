import styled from "styled-components";

export const MapsContainer = styled.div`
  height: 500px;
  background-color: lightgray;

  .mapContainer {
    width: 100%;
    height: 500px;
  }
`;
