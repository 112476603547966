import { FooterMain } from "./footer.style";
import LogoParavStrong from "../../../assets/website/logos/paravStrong.png";
import languageContent from "../../../common/website/languageAndContent";
import { useLanguage } from "../../../stateManagement/website/context/language";

const Footer = () => {
  const { currentLanguage } = useLanguage();
  return (
    <FooterMain>
      <div className="item">
        <div className="subItem">
          <img src={LogoParavStrong} alt="logo" width={130} />
        </div>
        <div className="subItem">
          <p>{languageContent.footer.description[currentLanguage]}</p>
        </div>
        <div
          className="subItem"
          style={{
            marginTop: -50,
          }}
        >
          <p>{languageContent.footer.designed.year}</p>
          <p style={{ marginTop: -11 }}>
            {languageContent.footer.designed.text[currentLanguage]}
          </p>
        </div>
      </div>
      <div className="item">
        <p className="servicesTitle">
          {languageContent.footer.services.title[currentLanguage]}
        </p>
        <div className="serviceWraper">
          <p className="servicesItem">
            {languageContent.footer.services.typeOfServices[0][currentLanguage]}
          </p>
          <p className="servicesItem">
            {languageContent.footer.services.typeOfServices[1][currentLanguage]}
          </p>
        </div>
        <div className="serviceWraper">
          <p className="servicesItem">
            {languageContent.footer.services.typeOfServices[2][currentLanguage]}
          </p>
          <p className="servicesItem">
            {languageContent.footer.services.typeOfServices[3][currentLanguage]}
          </p>
        </div>
        <div className="serviceWraper">
          <p className="servicesItem">
            {languageContent.footer.services.typeOfServices[4][currentLanguage]}
          </p>
          <p className="servicesItem">
            {languageContent.footer.services.typeOfServices[5][currentLanguage]}
          </p>
        </div>
      </div>
      <div className="item" style={{ flex: 0.5 }}>
        <p></p>
      </div>
    </FooterMain>
  );
};

export default Footer;
