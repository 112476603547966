import styled from "styled-components";
import layout from "../../../../../common/layout";

export const MainContainer = styled.div`
  width: 33.3%;
  display: grid;

  .withPadding {
    padding: 12px;
    display: grid;
  }

  .card {
    border: 1px solid #e3e3e3;
    display: flex;
    flex-direction: column;
    padding: 20px 40px;
    border-radius: 10px;
    transition: 0.5s;

    .iconCircleContainer {
      width: 80px;
      height: 80px;
      border: 3px solid ${layout.colors.mainColor};
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.5s;
      .icon {
        font-size: 40px;
        color: ${layout.colors.mainColor};
        transition: 0.5s;
      }
    }
  }
  .card:hover {
    box-shadow: 0px 0px 13px 0px lightgrey;
    transition: 0.5s;

    .iconCircleContainer {
      background-color: ${layout.colors.mainColor};
      transition: 0.5s;
      .icon {
        color: white;
        transition: 0.5s;
      }
    }
  }

  h2 {
    margin-top: 30px;
    letter-spacing: 1px;
    font-weight: 700;
    font-size: 20px;
    height: 40px;
  }

  p {
    margin-top: 5px;
    text-align: justify;
    color: ${layout.colors.grayTextStrong};
  }

  .quoteBtn {
    font-size: 12px;
    text-decoration: none;
    color: ${layout.colors.grayBg};
    background-color: ${layout.colors.mainColor};
    padding: 13px 30px;
    border-radius: 5px;
    font-weight: 700;
  }

  @media only screen and (max-width: ${layout.screenResolutions.desktop}) {
    width: 100%;
    margin-bottom: 50px;
    .withPadding {
      padding: 0px;
    }
    h2 {
      margin-top: 40px;
      height: auto;
    }

    p {
      margin-top: 0px;
      height: auto;
    }
  }
`;
