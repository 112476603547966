import { useContext, createContext, useState } from "react";

interface IContact {
  data: IData;
  handleData: (target: any) => {};
}

interface IData {
  subject: string;
  message: string;
}
const ContactContext = createContext<IContact | null>(null);

export const useContact = () => {
  const contexValue = useContext(ContactContext);
  if (!contexValue) {
    throw new Error("useContact hook must be called from ContactProvider");
  }
  return contexValue;
};

export const ContactProvider = (props: any) => {
  const [data, setData] = useState<IData>({
    subject: "",
    message: "",
  });

  const handleData = (target: any) => {
    setData({ ...data, [target.name]: target.value });
  };

  const value = { data, handleData };
  return <ContactContext.Provider value={value} {...props} />;
};
