import styled from "styled-components";
import layout from "../../../../../common/layout";

export const MainContainer = styled.div`
  width: 33.3%;

  .withPadding {
    padding: 12px;
  }
  .img {
    width: 100%;
    height: 290px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    cursor: pointer;
  }

  h2 {
    margin-top: 30px;
    letter-spacing: 1px;
    font-weight: 600;
    font-size: 22px;
    height: 40px;
  }

  p {
    text-align: justify;
    color: ${layout.colors.grayTextStrong};
    /* height: 130px; */
  }

  .overlay {
    background-color: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 290px;
    display: flex;
    justify-content: flex-end;
  }

  .overlayHover {
    justify-content: center;
    align-items: center;
    top: 20px;
    bottom: 20px;
    left: 20px;
    right: 20px;
    position: absolute;
    border-radius: 8px;
  }

  .visible {
    display: flex;
    background-color: rgba(255, 255, 255, 0.7);
    transition: 0.7s;
  }

  .notVisible {
    display: flex;
    visibility: hidden;
  }

  .quoteBtn {
    font-size: 14px;
    text-decoration: none;
    color: ${layout.colors.grayBg};
    background-color: ${layout.colors.mainColor};
    padding: 13px 30px;
    border-radius: 5px;
    font-weight: 700;
  }
  .quoteMobile {
    padding-top: 10px;
    display: none;
  }

  .icon {
    font-size: 30px;
    color: white;
    margin: 20px;
  }

  @media only screen and (max-width: ${layout.screenResolutions.desktop}) {
    width: 100%;
    margin-bottom: 50px;
    .withPadding {
      padding: 0px;
    }
    .visible {
      visibility: hidden;
    }

    .notVisible {
      visibility: hidden;
    }
    h2 {
      margin-top: 40px;
      height: auto;
    }

    p {
      margin-top: 0px;
      height: auto;
    }
    .quoteMobile {
      display: flex;
    }
    .img {
      width: 100%;
      height: 300px;
    }
    .overlay {
      height: 300px;
    }
  }
`;
