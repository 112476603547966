import styled from "styled-components";
import layout from "../../../../common/layout";

export const MainContainer = styled.div`
  display: flex;
  margin-top: 50px;

  .item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    cursor: pointer;
    height: 50px;
  }

  .text {
    font-family: "Raleway", sans-serif;
    color: ${layout.colors.grayTextStrong};
    text-align: center;
    font-weight: 800;
    letter-spacing: 1px;
    font-size: 15px;
  }

  .selectedText {
    color: ${layout.colors.mainColor};
  }

  .line {
    width: 100%;
    height: 4px;
    border-radius: 50px;
  }

  .selectedLine {
    background-color: ${layout.colors.mainColor};
  }

  @media only screen and (max-width: ${layout.screenResolutions.desktop}) {
    flex-direction: column;
    margin-bottom: 30px;

    .text {
      text-align: left !important;
    }
    .item {
      align-items: center;
      justify-content: flex-start;
    }
  }
`;
