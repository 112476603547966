import styled from "styled-components";
import layout from "../../../../common/layout";

export const MobileContainer = styled.div`
  background-color: ${layout.colors.mainColor};
  /* background-color: orange; */
  width: 100%;
  display: flex;
  font-size: 20px;
  margin-top: -1px;

  /* MOBILE MENU */
  .mobileMenuVisible {
    height: 500px;
    border-top: 1px solid ${layout.colors.grayButton};
    transition: 0.5s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    list-style: none;
    li {
      margin: 5px 0px;
      width: 100%;
    }
    a {
      transition: 0.5s;
      display: flex;
      justify-content: center;
      width: 100%;
      /* background-color: blue; */
      text-decoration: none;
      padding: 20px 0px;
      color: white;
      visibility: hidden;
      animation: cssAnimation 0s 0.2s forwards;

      @keyframes cssAnimation {
        to {
          visibility: visible;
        }
      }
    }
  }

  .mobileMenuHided {
    border-top: none;
    height: 0px;
    transition: 0.5s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-decoration: none;
    list-style: none;
    li {
      margin: 5px 0px;
      width: 100%;
    }
    a {
      transition: 0.5s;
      display: none;
      justify-content: center;
      width: 100%;
      /* background-color: blue; */
      text-decoration: none;
      padding: 20px 0px;
      background-color: ${layout.colors.mainColor};
    }
  }
`;
