import { RedBox } from "./goToTop.style";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const GoToTop = () => {
  return (
    <RedBox href="#home">
      <KeyboardArrowUpIcon />
    </RedBox>
  );
};

export default GoToTop;
