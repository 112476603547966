import React, { useState } from "react";
import "animate.css";
// WEBSITE
import Website from "./componets/website";
import { LanguageProvider } from "./stateManagement/website/context/language";
import { MenuProvider } from "./stateManagement/website/context/menu";
import {ContactProvider} from './stateManagement/website/context/contact';

function App() {
  const [user, setUser] = useState<Boolean>(false);
  return (
    <div className="App">
      {user ? (
        <div>app</div>
      ) : (
        <LanguageProvider>
          <MenuProvider>
            <ContactProvider>
              <Website />
            </ContactProvider>
          </MenuProvider>
        </LanguageProvider>
      )}
    </div>
  );
}

export default App;
