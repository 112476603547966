import { MainContainer, TitleContainer, ImageContainer } from "./home.style";
import ButtonBase from "@mui/material/ButtonBase";
import certificationLogo from "../../../assets/website/home/certificationLogo.png";
import languageContent from "../../../common/website/languageAndContent";
import { useLanguage } from "../../../stateManagement/website/context/language";

const Home = () => {
  const { currentLanguage } = useLanguage();
  return (
    <MainContainer id="home">
      <div className="subContainer">
        <TitleContainer>
          <div>
            <h1>{languageContent.home.title[currentLanguage]}</h1>
            <p>{languageContent.home.description[currentLanguage]}</p>
            <ButtonBase
              style={{
                borderRadius: 10,
                zIndex: "0!important",
                height: "auto",
              }}
            >
              <a href="#contact">
                {languageContent.home.button[currentLanguage]}
              </a>
            </ButtonBase>
          </div>
        </TitleContainer>
        <ImageContainer>
          <div className="mainImg">
            <div className="certificationContainer">
              <div className="imgCertificationContainer">
                <img src={certificationLogo} alt="certificationLogo" />
              </div>
              <div className="numberCertificationContainer">
                <p>400-401945</p>
              </div>
            </div>
          </div>
        </ImageContainer>
      </div>
    </MainContainer>
  );
};

export default Home;
