import { MainContainer } from "./duns.style";
import dunsLogo from "../../../assets/website/duns/dunsLogo.png";

const Duns = () => {
  return (
    <MainContainer>
      <div>
        <img src={dunsLogo} alt="dunsLogo" />
        <p>
          Nuestro registro DUNS 95-160-1950 ante Dun & Bradstreet le ofrece la
          confianza y seguridad de realizar negocios con nosotros.
        </p>
      </div>
    </MainContainer>
  );
};

export default Duns;
