import styled from "styled-components";
import layout from "../../../common/layout";
import bg from "../../../assets/website/duns/bg.png";
import bgMobile from "../../../assets/website/duns/bgMobile.png";

export const MainContainer = styled.div`
  width: 100%;
  height: 500px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${bg});

  div {
    padding: ${layout.designGlobalRules.desktop.padding};
    text-align: center;
  }

  img {
    margin-top: 120px;
    margin-bottom: 10px;
    width: 150px;
  }

  p {
    color: #e8e8e8;
    text-align: center;
    letter-spacing: 1px;
    font-size: 24px;
  }
  @media only screen and (max-width: ${layout.screenResolutions.desktop}) {
    background-image: url(${bgMobile});
    div {
      padding: ${layout.designGlobalRules.mobile.padding};
    }
    p {
      font-size: 16px;
    }
  }
`;
