import { FC } from "react";
import Navbar from "./01_menu/navbar/navbar";
import GoToTop from "./01_menu/goToTop/goToTop";
import Home from "./02_home/Home";
import AboutUs from "./03_about_us/AboutUs";
import Services from "./04_services/Services";
import Duns from "./05_duns/Duns";
import Clients from "./06_clients/Clients";
import Maps from "./07_maps/Maps";
import Contact from "./08_contact/Contact";
import Footer from "./09_footer/Footer";

const Website: FC = () => {
  return (
    <>
      <Navbar />
      <GoToTop />
      <Home />
      <AboutUs />
      <Services />
      <Duns />
      <Clients />
      <Maps />
      <Contact />
      <Footer />
      <p> </p>
    </>
  );
};

export default Website;
