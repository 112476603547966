import { FC } from "react";
import { useMenu } from "../../../../stateManagement/website/context/menu";
import { MobileContainer } from "./mobile.style";
const Mobile = ({ mobileMenu, showMobileMenu }: any) => {
  const { setShowGalery, setShowServices } = useMenu();
  return (
    <MobileContainer>
      <div className={mobileMenu ? "mobileMenuVisible" : "mobileMenuHided"}>
        <li
          onClick={() => {
            showMobileMenu(false);
          }}
        >
          <a href="#home">Inicio</a>
        </li>
        <li
          onClick={() => {
            showMobileMenu(false);
          }}
        >
          <a href="#aboutUs">Nosotros</a>
        </li>
        <li
          onClick={() => {
            showMobileMenu(false);
            setShowServices(true);
          }}
        >
          <a href="#services">Servicios</a>
        </li>
        <li
          onClick={() => {
            showMobileMenu(false);
            setShowGalery(true);
          }}
        >
          <a href="#services">Galería</a>
        </li>
        <li
          onClick={() => {
            showMobileMenu(false);
          }}
        >
          <a href="#clients">Clientes</a>
        </li>
        <li
          onClick={() => {
            showMobileMenu(false);
          }}
        >
          <a href="#contact">Contacto</a>
        </li>
      </div>
    </MobileContainer>
  );
};

export default Mobile;
