// import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { MainContainer } from "./modal.style";
import Carousel from "react-material-ui-carousel";
import { useLanguage } from "../../../../../../stateManagement/website/context/language";

// =================================================================================
const ModalGaleryView = ({ openModal, handleCloseModal, content }: any) => {
  const { currentLanguage } = useLanguage();

  // ================================
  //  RETURN DE IMAGE ELEMENT
  // ================================
  const returnImage = (item: any, i: number) => {
    return (
      <div
        className="img"
        style={{ backgroundImage: `url(${item.img})` }}
        key={i}
      >
        <div className="overlay">
          {!content.groupOfItems && <p className="description">{item.text}</p>}
        </div>
      </div>
    );
  };
  // ================================
  //  RETURN DE VIDEO ELEMENT
  // ================================
  const returnVideo = (item: any, i: number, autoplay: boolean) => {
    return (
      <div key={i}>
        {!content.groupOfItems && <p className="videoText">{item.text}</p>}
        <video width="400" controls autoPlay={autoplay} muted>
          <source src={item.video} type="video/mp4" />
          Your browser does not support HTML video.
        </video>
      </div>
    );
  };

  // ================================
  //  SELECTOR OF VIDEO OR IMAGE TO RENDER
  // ================================
  const ElementToRender = (item: any, i: number, autoplay: boolean) =>
    item.type === "img" ? returnImage(item, i) : returnVideo(item, i, autoplay);

  // ================================
  //  CAROUSEL
  // ================================
  const GroupCarousel = (
    <Carousel
      autoPlay={false}
      height="500px"
      className="carousel"
      indicators={true}
      navButtonsAlwaysVisible={true}
    >
      {content.groupOfItems &&
        content.groupOfItems.map((item: any, i: number) => (
          <div>
            <p className="carouselDescription">
              {item.description && item.description[currentLanguage]}
            </p>
            {ElementToRender(item, i, true)}
          </div>
        ))}
    </Carousel>
  );

  return (
    <Modal
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <MainContainer>
        {content.typeOfItem === "group"
          ? GroupCarousel
          : ElementToRender(content.item, 0, true)}
      </MainContainer>
    </Modal>
  );
};

export default ModalGaleryView;
