import styled from "styled-components";
import layout from "../../../common/layout";
import imagebg from "../../../assets/website/aboutUs/image.png";

export const MainContainer = styled.div`
  display: flex;
  height: 70vh;
  background-color: ${layout.colors.grayBg};
  padding: ${layout.designGlobalRules.desktop.padding};

  /* 
  ======================
    IMAGE CONTAINER 
  ======================
  */
  .imageContainer {
    display: flex;
    flex: 1.2;

    .imgDecoration {
      position: relative;
      width: 105px;
      height: 131px;
      margin-top: 75px;
    }

    .image {
      margin-top: 110px;
      margin-left: -70px;
      width: 80%;
      height: 70%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url(${imagebg});
    }

    @media only screen and (max-width: ${layout.screenResolutions.desktop}) {
      flex: 1;
      .image {
        z-index: 1;
        margin-top: 80px;
        margin-left: -105px;
        width: 100%;
        height: 82%;
      }
      .imgDecoration {
        margin-top: 55px;
      }
    }
  }
  /* 
  ======================
    TEXT CONTAINER 
  ======================
  */

  .textContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    margin-top: -50px;

    h1 {
      margin-top: 0px;
      font-size: ${layout.designGlobalRules.desktop.subtitleFontSize};
    }
    @media only screen and (max-width: ${layout.screenResolutions.desktop}) {
      margin-top: 0px;
      justify-content: flex-start;
      padding-top: 50px;
      h1 {
        font-size: ${layout.designGlobalRules.mobile.subtitleFontSize};
      }
    }
  }

  /* 
  ======================
    MAIN MEDIA QUERIES 
  ======================
  */
  @media only screen and (max-width: ${layout.screenResolutions.desktop}) {
    padding: ${layout.designGlobalRules.mobile.padding};
    flex-direction: column;
    height: 120vh;

    .imageContainer {
      flex: 0.4;
    }
    .textContainer {
      flex: 0.6;
    }
  }
`;
