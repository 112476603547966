import styled from "styled-components";
import bg from "../../../assets/website/home/bg.png";
import layout from "../../../common/layout";
import mainImg from "../../../assets/website/home/mainImage.png";

export const MainContainer = styled.div`
  width: 100%;
  height: 85vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${bg});
  .subContainer {
    display: flex;
    flex: 1;
    height: 85vh;
    padding: ${layout.designGlobalRules.desktop.padding};

    @media only screen and (max-width: ${layout.screenResolutions.desktop}) {
      padding: ${layout.designGlobalRules.mobile.padding};
      padding-top: 20px;
      padding-bottom: 20px;
      flex-direction: column;
      height: 80vh;
    }
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100;
  margin-top: 100px;
  align-items: center;
  /* background-color: orange; */
  div {
    width: 85%;
    text-align: justify;

    h1 {
      color: white;
      letter-spacing: 1px;
      font-size: ${layout.designGlobalRules.desktop.titleFontSize};
      font-weight: 800;
    }
    p {
      letter-spacing: 1px;
      color: lightgray;
      font-size: 18px;
      margin-bottom: 40px;
    }
    a {
      font-size: 14px;
      text-decoration: none;
      color: ${layout.colors.mainColor};
      background-color: ${layout.colors.grayBg};
      padding: 13px 30px;
      border-radius: 5px;
      font-weight: 700;
    }
    @media only screen and (max-width: ${layout.screenResolutions.desktop}) {
      width: 100%;

      h1 {
        font-size: ${layout.designGlobalRules.mobile.titleFontSize};
      }
      p {
        letter-spacing: 0.5px;
        font-size: 15px;
        margin-top: -5px;
        margin-bottom: 20px;
      }
    }
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  flex: 1;
  margin-top: 100px;
  align-items: center;
  justify-content: flex-end;
  /* background-color: blue; */
  /* padding-left: 140px; */
  .mainImg {
    width: 100%;
    height: 65%;
    border-radius: 17px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(${mainImg});
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .certificationContainer {
    margin-bottom: -70px;
    margin-right: -55px;
    display: flex;
    flex-direction: column;

    .imgCertificationContainer {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      img {
        width: 150px;
      }
    }

    .numberCertificationContainer {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      p {
        margin-top: 5px;
        color: white;
        font-weight: 500;
      }
    }
  }

  @media only screen and (max-width: ${layout.screenResolutions.desktop}) {
    margin-top: 20px;

    .mainImg {
      height: 90%;
    }
    .certificationContainer {
      margin-bottom: -30px;
      margin-right: -25px;

      .imgCertificationContainer {
        flex: 1;
        img {
          width: 100px;
        }
      }

      .numberCertificationContainer {
        align-items: flex-end;
        p {
          margin-bottom: 2px;
          font-size: 12px;
        }
      }
    }
  }
`;
