import { MainContainer } from "./clients.style";
import clientsImg from "../../../assets/website/clients/clients.png";

const Clients = () => {
  return (
    <MainContainer id="clients">
      <div className="subContainer">
        <h2 className="title">Nuestros Clientes Distinguidos</h2>
        <p className="subtitle">
          Algunos de nuestros clientes frecuentes que recomiendan nuestros
          servicios
        </p>
        {/* first Row */}
        <div className="clientsImg">
          <img src={clientsImg} alt="clientsImg" />
        </div>
      </div>
    </MainContainer>
  );
};
export default Clients;
