import styled from "styled-components";
import layout from "../../../common/layout";

export const MainContainer = styled.div`
  height: 55vh;
  background-color: ${layout.colors.grayBg};
  padding-top: 150px;
  padding-bottom: 150px;

  .subContainer {
    padding: ${layout.designGlobalRules.desktop.padding};
  }
  .title {
    text-align: center;
    font-size: ${layout.designGlobalRules.desktop.subtitleFontSize};
  }

  .subtitle {
    padding-top: 10px;
    padding-bottom: 30px;
    letter-spacing: 1px;
    text-align: center;
    font-size: 18px;
    color: ${layout.colors.grayTextStrong};
    width: 50%;
    margin: auto;
  }
  .clientsImg {
    text-align: center;

    img {
      margin-top: 25px;
      width: 50%;
    }
  }
  @media only screen and (max-width: ${layout.screenResolutions.desktop}) {
    .subContainer {
      padding: ${layout.designGlobalRules.mobile.padding};
    }

    .subtitle {
      font-size: 16px;
      padding-bottom: 10px;
      width: 100%;
      text-align: justify;
    }

    .clientsImg {
      img {
        margin-top: 35px;
        width: 100%;
      }
    }
  }
`;
