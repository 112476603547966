import languageContent from "../../../common/website/languageAndContent";
import { useLanguage } from "../../../stateManagement/website/context/language";
import { MainContainer } from "./contact.style";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Form from "./form/Form";
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from "react";

const Contact = () => {
  const { currentLanguage } = useLanguage();
  const { title, email, phone } = languageContent.contact.cards.contact;
  const titleAddress = languageContent.contact.cards.address.title;
  const { street, country } = languageContent.contact.cards.address;
  const titleSchedule = languageContent.contact.cards.schedule.title;
  const { time, days } = languageContent.contact.cards.schedule;

  const [alertMesssage, setAlertMessage] = useState("");

  const cardInformation = (
    Icon: any,
    title: string,
    firstDescription: string,
    secondDescription: string
  ) => {
    return (
      <div className="cardInformation">
        <div style={{ flex: 0.2 }}>
          <div className="iconCircle">
            <Icon className="icon" />
          </div>
        </div>
        <div style={{ flex: 0.8 }}>
          <p className="titleCard">{title}</p>
          <p className="descriptionCard">{firstDescription}</p>
          <p className="descriptionCard">{secondDescription}</p>
        </div>
      </div>
    );
  };
  return (
    <MainContainer id="contact">
      <p className="titleAditional">
        {languageContent.contact.title[currentLanguage]}
      </p>
      <h2 className="title">
        {languageContent.contact.subtitle[currentLanguage]}
      </h2>
      <p className="subtitle">
        {languageContent.contact.description[currentLanguage]}
      </p>
      <div className="formContainer">
        <div className="cardInformationContainer">
          {cardInformation(PhoneIcon, title[currentLanguage], phone, email)}
          {cardInformation(
            LocationOnIcon,
            titleAddress[currentLanguage],
            street,
            country
          )}
          {cardInformation(
            AccessTimeIcon,
            titleSchedule[currentLanguage],
            days[currentLanguage],
            time[currentLanguage]
          )}
        </div>
        <div className="cardFormContainer">
          <Form setAlertMessage={setAlertMessage}/>
        </div>
      </div>
      {alertMesssage && (
        <Alert
        severity={alertMesssage === languageContent.contact.form.submitBtn.sent[currentLanguage] ? "success" : "error"}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setAlertMessage("");
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{ mb: 2 }}
        className="alert"
        >
        {alertMesssage}
        </Alert>
      )}
     
    </MainContainer>
  );
};

export default Contact;
