import styled from "styled-components";
import layout from "../../../common/layout";

export const FooterMain = styled.div`
  background-color: ${layout.colors.grayBg};
  height: 400px;
  padding: ${layout.designGlobalRules.desktop.padding};
  display: flex;

  .item {
    flex: 1;
    padding: 20px;
    padding-top: 100px;
    display: flex;
    flex-direction: column;
  }

  .subItem {
    flex: 1;
    p {
      color: gray;
      font-size: 15px;
      letter-spacing: 0.2px;
    }
  }

  .servicesTitle {
    font-weight: 900;
    font-size: 18px;
    letter-spacing: 0.2px;
    margin-bottom: 65px;
  }

  .servicesItem {
    color: gray;
    font-size: 15px;
    letter-spacing: 0.2px;
    flex: 0.35;
  }

  .serviceWraper {
    display: flex;
    margin-bottom: -10px;
  }

  @media only screen and (max-width: ${layout.screenResolutions.desktop}) {
    flex-direction: column;
    height: auto;
    padding: ${layout.designGlobalRules.mobile.padding};
    .item {
      .subItem {
        padding: 25px 0px;
      }
    }

    .serviceWraper {
      flex-direction: column;
      margin: 0px;
    }
  }
`;
