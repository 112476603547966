import { MainContainer, List } from "./services.style";
import ServiceItem from "./options/services/Item";
import GaleryItem from "./options/galery/Item";
import Tabs from "./tabs/Tabs";
import { useEffect, useState } from "react";
import useServiceCategory from "./options/options.hook";
import ButtonBase from "@mui/material/ButtonBase";
import { useMenu } from "../../../stateManagement/website/context/menu";
import languageContent from "../../../common/website/languageAndContent";
import { useLanguage } from "../../../stateManagement/website/context/language";

const Services = () => {
  const [currentService, setCurrentService] = useState<string>(""); // metalmecanicos || electromecanicos || etc
  const [listOption, setListOption] = useState<string>("services"); // services || portfolio

  const elements: any = useServiceCategory(currentService, listOption);
  const { showGalery, setShowGalery, showServices, setShowServices } =
    useMenu();
  const { currentLanguage } = useLanguage();

  useEffect(() => {
    if (showGalery) {
      setListOption("galery");
      setShowGalery(false);
    } else if (showServices) {
      setListOption("services");
      setShowServices(false);
    }
  }, [listOption, showGalery, showServices]);

  const handleTypeOfItem = (item: any): string => {
    type validTypes = "img" | "video" | "group";

    let typeToReturn: validTypes;
    if (item.length) {
      typeToReturn = "group";
    } else if (item.type === "img") {
      typeToReturn = "img";
    } else {
      typeToReturn = "video";
    }
    return typeToReturn;
  };
  const item = elements.map((item: any, key: any) => {
    return listOption === "services" ? (
      <ServiceItem
        item={item}
        index={key}
        key={key}
        quoteBtnText={languageContent.services.btnQuote[currentLanguage]}
      />
    ) : (
      <GaleryItem
        item={item.length ? item[0] : item}
        index={key}
        key={key}
        typeOfItem={handleTypeOfItem(item)}
        groupOfItems={item.length ? item : null}
      />
    );
  });

  return (
    <MainContainer id="services">
      <div style={{ textAlign: "center" }}>
        <ButtonBase
          className="portfolioBtn"
          onClick={() => {
            setListOption(listOption === "galery" ? "services" : "galery");
          }}
        >
          {listOption === "services"
            ? languageContent.galery.btnView[currentLanguage]
            : languageContent.services.btnView[currentLanguage]}
        </ButtonBase>
      </div>
      <h2 className="title">
        {listOption === "services"
          ? languageContent.services.title[currentLanguage]
          : languageContent.galery.title[currentLanguage]}
      </h2>
      <p className="subtitle">
        {listOption === "services"
          ? languageContent.services.description[currentLanguage]
          : languageContent.galery.description[currentLanguage]}
      </p>
      <Tabs
        currentService={currentService}
        setCurrentService={setCurrentService}
      />
      <List>{item}</List>
    </MainContainer>
  );
};

export default Services;
