import { useEffect, useState } from "react";
import languageContent from "../../../../common/website/languageAndContent";
import { useContact } from "../../../../stateManagement/website/context/contact";
import { useLanguage } from "../../../../stateManagement/website/context/language";
import { MainContainer } from "./form.style";

interface IDataForm {
  name: string;
  email: string;
  phone: number | string;
  subject: string;
  message: string;
}

const Form = (props: any) => {
  const { setAlertMessage } = props;
  const [sendingMessage, setSendingMessage] = useState(false);

  const { currentLanguage } = useLanguage();
  const { data } = useContact();

  const [dataForm, setDataForm] = useState<IDataForm>({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  useEffect(() => {
    console.log("entrando a use effect de form");
    console.log(data);
    if (data.subject) {
      setDataForm({ ...dataForm, subject: data.subject });
    } else if (data.message) {
      setDataForm({ ...dataForm, message: data.message });
    }
  }, [data]);

  const handleDataForm = (target: any) => {
    // console.log(target.value);
    setDataForm({ ...dataForm, [target.name]: target.value });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setSendingMessage(true);
    const result = await fetch(
      "https://us-central1-parav-373923.cloudfunctions.net/sendMail",
      {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify(dataForm),
      }
    );

    if (result.ok) {
      // alert("correo enviado");
      setSendingMessage(false);
      setAlertMessage(
        languageContent.contact.form.submitBtn.sent[currentLanguage]
      );
    } else {
      setSendingMessage(false);
      setAlertMessage(
        languageContent.contact.form.submitBtn.error[currentLanguage]
      );
    }
  };

  return (
    <MainContainer>
      <form onSubmit={handleSubmit}>
        <div className="itemContainer">
          <input
            value={dataForm.name}
            type="text"
            placeholder={languageContent.contact.form.name[currentLanguage]}
            name="name"
            onChange={(e) => {
              handleDataForm(e.target);
            }}
          />
          <input
            value={dataForm.email}
            name="email"
            type="email"
            placeholder={languageContent.contact.form.email[currentLanguage]}
            onChange={(e) => {
              handleDataForm(e.target);
            }}
          />
        </div>
        <div className="itemContainer">
          <input
            value={dataForm.phone}
            name="phone"
            type="phone"
            placeholder={languageContent.contact.form.phone[currentLanguage]}
            onChange={(e) => {
              handleDataForm(e.target);
            }}
          />
          <input
            value={dataForm.subject}
            name="subject"
            type="text"
            placeholder={languageContent.contact.form.subject[currentLanguage]}
            onChange={(e) => {
              handleDataForm(e.target);
            }}
          />
        </div>
        <textarea
          value={dataForm.message}
          onChange={(e) => {
            handleDataForm(e.target);
          }}
          name="message"
          id=""
          placeholder={languageContent.contact.form.message[currentLanguage]}
        ></textarea>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            type="submit"
            disabled={
              (!dataForm.name &&
                !dataForm.email &&
                !dataForm.phone &&
                !dataForm.subject &&
                !dataForm.message) ||
              sendingMessage
            }
          >
            {sendingMessage
              ? languageContent.contact.form.submitBtn.sending[
                  currentLanguage
                ].toUpperCase()
              : languageContent.contact.form.submitBtn.before[
                  currentLanguage
                ].toUpperCase()}
          </button>
        </div>
      </form>
    </MainContainer>
  );
};

export default Form;
