import { MainContainer } from "./aboutUs.style";
import imgDecoration from "../../../assets/website/aboutUs/decoration.png";
import Options from "./Options";
import { useLanguage } from "../../../stateManagement/website/context/language";
import languageContent from "../../../common/website/languageAndContent";

const AboutUs = () => {
  const { currentLanguage } = useLanguage();
  return (
    <MainContainer id="aboutUs">
      <div className="imageContainer">
        <img
          className="imgDecoration"
          src={imgDecoration}
          alt="imgDecoration"
        />
        <div className="image"></div>
      </div>
      <div className="textContainer">
        <div style={{ display: "flex" }}>
          <p className="lineSubtitle"></p>
          <h3>{languageContent.aboutUs.title[currentLanguage]}</h3>
        </div>
        <h1>{languageContent.aboutUs.description[currentLanguage]}</h1>
        <Options />
      </div>
    </MainContainer>
  );
};

export default AboutUs;
