const layout: any = {
  colors: {
    mainColor: "#B54A4A",
    grayBg: "#FAFAFA",
    grayStrong: "#EFEFEF",
    grayText: "#CECECE",
    grayTextStrong: "#6d6d6d",
    grayButton: "#D9D9D9",
  },
  designGlobalRules: {
    desktop: {
      padding: "0px 250px",
      titleFontSize: "43px",
      subtitleFontSize: "35px",
    },
    mobile: {
      padding: "0px 40px",
      titleFontSize: "31px",
      subtitleFontSize: "28px",
    },
  },
  screenResolutions: {
    desktop: "1000px",
    mobile: "670px",
  },
};

export default layout;
