import styled from "styled-components";
import layout from "../../../common/layout";

export const MainContainer = styled.div`
  .items {
    background-color: ${layout.colors.grayStrong};
    display: flex;
    padding: 15px 20px;
    border-radius: 5px;

    .fakeItem {
      flex: 1;
    }
    .item {
      flex: 1;
      background-color: white;
      color: black;
      margin-right: 20px;
      padding: 10px 10px;
      font-weight: 700;
    }

    .itemSelected {
      background-color: ${layout.colors.mainColor};
      color: white;
    }

    @media only screen and (max-width: ${layout.screenResolutions.desktop}) {
      flex-direction: column;
      .item {
        padding: 10px 10px;
        margin-right: 0px;
        margin-bottom: 10px;
      }

      .onlyDesktop {
        display: none;
      }
    }
  }

  .text {
    padding-top: 10px;
    letter-spacing: 1px;
    text-align: justify;
    font-size: 18px;
    color: ${layout.colors.grayTextStrong};
    /* background-color: blue; */
    height: 150px;

    @media only screen and (max-width: ${layout.screenResolutions.desktop}) {
      letter-spacing: 0.5px;
      font-size: 16px;
    }
  }
`;
