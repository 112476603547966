import { FC, useEffect, useState } from "react";
import {
  MainContainer,
  LogoContainer,
  ItemsContainer,
  SideMenuContainer,
} from "./navbar.style";
import logoMenu from "../../../../assets/website/logos/menu.png";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import Mobile from "../mobile/mobile";
import CloseIcon from "@mui/icons-material/Close";
import { useMenu } from "../../../../stateManagement/website/context/menu";

const Navbar: FC = () => {
  const [scrollIsTop, setScrollIsTop] = useState(true);
  const [mobileMenu, setMobileMenu] = useState(false);
  const { setShowGalery, setShowServices } = useMenu();

  useEffect(() => {
    window.addEventListener("scroll", () => {
      const isTop = window.scrollY < 15;
      isTop === true ? setScrollIsTop(true) : setScrollIsTop(false);
    });
  }, []);

  const showMobileMenu = () => {
    setMobileMenu(!mobileMenu);
  };

  return (
    <MainContainer>
      <div
        className={
          scrollIsTop && !mobileMenu
            ? "subContainer bgScrollTop"
            : "subContainer bgScrollDown"
        }
      >
        {/* LOGO  */}
        <LogoContainer>
          <img className="logo" src={logoMenu} alt="logoParavMenu" />
        </LogoContainer>
        {/* DESKTOP MENU OPTIONS CENTER */}
        <ItemsContainer>
          <li>
            <a href="#home">Inicio</a>
          </li>
          <li>
            <a href="#aboutUs">Nosotros</a>
          </li>
          <li
            onClick={() => {
              setShowServices(true);
            }}
          >
            <a href="#services">Servicios</a>
          </li>
          <li
            onClick={() => {
              setShowGalery(true);
            }}
          >
            <a href="#services">Galería</a>
          </li>
          <li>
            <a href="#clients">Clientes</a>
          </li>
          <li>
            <a href="#contact">Contacto</a>
          </li>
        </ItemsContainer>
        {/* BUTTON MENU RIGHT TOP */}
        <SideMenuContainer>
          {/* for desktop */}
          <button className="desktop">
            <MenuRoundedIcon />
          </button>
          {/* for mobile */}
          <button
            className="mobile"
            onClick={() => {
              showMobileMenu();
            }}
          >
            {!mobileMenu ? <MenuRoundedIcon /> : <CloseIcon />}
          </button>
        </SideMenuContainer>
      </div>
      {/* 
      ======================
      RESPONSIVE MENUS
      ======================
      */}
      {/* MOBILE MENU */}
      <Mobile mobileMenu={mobileMenu} showMobileMenu={showMobileMenu} />
    </MainContainer>
  );
};
export default Navbar;
