import styled from "styled-components";
import layout from "../../../common/layout";

export const MainContainer = styled.div`
  padding: ${layout.designGlobalRules.desktop.padding};
  padding-top: 120px;
  padding-bottom: 120px;

  .titleAditional {
    text-align: center;
    color: ${layout.colors.mainColor};
    margin-bottom: -15px;
    font-weight: 700;
    font-size: 20px;
  }

  .title {
    text-align: center;
    font-size: ${layout.designGlobalRules.desktop.subtitleFontSize};
  }

  .subtitle {
    padding-top: 10px;
    padding-bottom: 30px;
    letter-spacing: 1px;
    text-align: center;
    font-size: 18px;
    color: ${layout.colors.grayTextStrong};
    width: 50%;
    margin: auto;
  }
  .formContainer {
    display: flex;
    margin-top: 40px;

    .cardInformationContainer {
      display: flex;
      flex-direction: column;
      flex: 0.3;

      .cardInformation {
        margin-top: 20px;
        /* border: 1px solid lightgray; */
        display: flex;
        padding: 20px;
        border-radius: 10px;
        transition: 0.3s;
        background-color: ${layout.colors.grayBg};

        .iconCircle {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background-color: ${layout.colors.mainColor};
          display: flex;
          justify-content: center;
          align-items: center;

          .icon {
            color: white;
          }
        }

        .titleCard {
          margin-top: 0px;
          margin-bottom: 15px;
          color: ${layout.colors.mainColor};
          font-weight: 800;
          font-size: 22px;
          letter-spacing: 0.2px;
        }

        .descriptionCard {
          margin-top: 5px;
          margin-bottom: 0px;
          letter-spacing: 0.2px;
          color: ${layout.colors.grayTextStrong};
          font-size: 16px;
        }
      }
    }

    .cardInformation:hover {
      box-shadow: -1px -1px 10px lightgrey;
      transition: 0.3s;
    }

    .cardFormContainer {
      display: flex;
      flex-direction: column;
      flex: 0.7;
    }
  }

  .alert{
      position:absolute;
      right:60px;
      width:350px;
      height:50px;
      margin-top:-76px;
    }

  @media only screen and (max-width: ${layout.screenResolutions.desktop}) {
    padding: ${layout.designGlobalRules.mobile.padding};
    padding-top: 50px;
    padding-bottom: 100px;
    .subtitle {
      font-size: 16px;
      padding-bottom: 10px;
      width: 100%;
      text-align: justify;
    }

    .formContainer {
      flex-direction: column-reverse;

      .cardInformationContainer {
        .cardInformation {
          .titleCard {
            font-weight: 700;
            font-size: 20px;
            padding-left: 15px;
          }
          .descriptionCard {
            font-size: 13px;
            padding-left: 15px;
          }
        }
      }
    }
  }
`;
