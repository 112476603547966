import { Button } from "@mui/material";
import { useState } from "react";
import languageContent from "../../../common/website/languageAndContent";
import { useLanguage } from "../../../stateManagement/website/context/language";
import { MainContainer } from "./options.style";

const Options = () => {
  const [currentOption, setCurrentOption] = useState("aboutUs");
  const { currentLanguage } = useLanguage();

  const showTextByOption = (currentOption: string): string => {
    let text = "";
    switch (currentOption) {
      case "aboutUs":
        text =
          languageContent.aboutUs.options.aboutUs.description[currentLanguage];
        break;
      case "vision":
        text =
          languageContent.aboutUs.options.vision.description[currentLanguage];
        break;
      default:
        text =
          languageContent.aboutUs.options.history.description[currentLanguage];
        break;
    }
    return text;
  };
  return (
    <MainContainer>
      <div className="items">
        <Button
          className={
            currentOption === "aboutUs" ? " item itemSelected" : "item"
          }
          onClick={() => {
            setCurrentOption("aboutUs");
          }}
        >
          {languageContent.aboutUs.options.aboutUs.btn[currentLanguage]}
        </Button>
        <Button
          className={currentOption === "vision" ? " item itemSelected" : "item"}
          onClick={() => {
            setCurrentOption("vision");
          }}
        >
          {languageContent.aboutUs.options.vision.btn[currentLanguage]}
        </Button>
        <Button
          className={
            currentOption === "history" ? " item itemSelected" : "item"
          }
          onClick={() => {
            setCurrentOption("history");
          }}
        >
          {languageContent.aboutUs.options.history.btn[currentLanguage]}
        </Button>
        <div className="fakeItem onlyDesktop"></div>
        <div className="fakeItem onlyDesktop"></div>
      </div>
      <p className="text">{showTextByOption(currentOption)}</p>
    </MainContainer>
  );
};
export default Options;
