import { useContext, createContext, useState } from "react";

type language = "spanish" | "english";
interface ILanguageContext {
  currentLanguage: language;
}

const LanguageContext = createContext<ILanguageContext | null>(null);

/**
 * custom hook to get and update the current phase
 * @param {any} props
 */
export const useLanguage = () => {
  const contextValue = useContext(LanguageContext);
  if (!contextValue) {
    throw new Error("useLanguage hook must be called from LanguageProvider");
  }
  return contextValue;
};

export const LanguageProvider = (props: any) => {
  const [currentLanguage, setCurrentLanguage] = useState("spanish");

  const handleLanguage = (currentLanguage: string) => {
    setCurrentLanguage(currentLanguage);
  };

  const value = { currentLanguage, handleLanguage };

  return <LanguageContext.Provider value={value} {...props} />;
};
