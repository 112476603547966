import { createContext, useContext, useState } from "react";

interface IMenu {
  showGalery: boolean;
  setShowGalery: (value: boolean) => {};
  showServices: boolean;
  setShowServices: (value: boolean) => {};
}
const Context = createContext<IMenu | null>(null);

/**
 * custom hook to get and update the the state
 * @param {any} props
 */
export const useMenu = () => {
  const contextValue = useContext(Context);
  if (!contextValue) {
    throw new Error("useMenu hook must be called from MenuProvider");
  }
  return contextValue;
};

// this function needs to be added in the app.tsx  tree to be use for the app componets
export const MenuProvider = (props: any) => {
  const [showGalery, setShowGalery] = useState(false);
  const [showServices, setShowServices] = useState(false);

  const value = {
    showGalery,
    setShowGalery,
    showServices,
    setShowServices,
  };
  return <Context.Provider value={value} {...props} />;
};
