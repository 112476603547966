import { MapsContainer } from "./maps.style";
import { useMemo, useReducer, useState } from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { mapStyles } from "./mapsStyling";

interface ILocationInfo {
  position: {
    lat: number;
    lng: number;
  };
  title: string;
}
const Maps = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyAJngl0xesPbkMd9kvTEJTEgiXSGcxcosY",
  });

  const [infoWindowContent, setInfoWindowContent] = useState<
    ILocationInfo | undefined
  >();
  const [paravPlaces, setParavPlaces] = useState<ILocationInfo[]>([
    {
      position: {
        lat: 22.12905623635405,
        lng: -100.97573011865697,
      },
      title: "San Luis Potosí.",
    },
    {
      position: {
        lat: 25.349718701745328,
        lng: -100.90051722058331,
      },
      title: "Saltillo Coahuila",
    },
    {
      position: {
        lat: 28.99333997516369,
        lng: -110.87610315808331,
      },
      title: "Hermosillo Sonora",
    },
  ]);

  const center = useMemo(
    () => ({ lat: 25.767766055772935, lng: -103.17134318226742 }),
    []
  );

  const MarkersList = paravPlaces.map((item, index) => (
    <Marker
      position={item.position}
      key={index}
      title={item.title}
      onClick={() => {
        setInfoWindowContent(item);
      }}
    ></Marker>
  ));

  if (!isLoaded) return <div>Loading maps...</div>;
  return (
    <MapsContainer>
      <GoogleMap
        zoom={6}
        center={center}
        mapContainerClassName="mapContainer"
        options={{ styles: mapStyles.silver }}
      >
        {isLoaded && MarkersList}
        {infoWindowContent && (
          <InfoWindow
            position={infoWindowContent.position}
            onCloseClick={() => setInfoWindowContent(undefined)}
          >
            <h1>{infoWindowContent.title}</h1>
          </InfoWindow>
        )}
      </GoogleMap>
    </MapsContainer>
  );
};

export default Maps;
