import { useState } from "react";
import { useLanguage } from "../../../../../stateManagement/website/context/language";
import { MainContainer } from "./item.style";
import ModalGaleryView from "./modal/modal";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import FilterNoneIcon from "@mui/icons-material/FilterNone";

interface localPorps {
  item: any;
  index: any;
  typeOfItem: string;
  groupOfItems?: any;
}
const GaleryItem = ({ item, index, typeOfItem, groupOfItems }: localPorps) => {
  const { currentLanguage } = useLanguage();

  // MODAL CONTROL
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const contentForModal = {
    typeOfItem,
    groupOfItems,
    item: {
      img: item.img,
      text: item.description[currentLanguage],
      type: item.type,
      video: item.video && item.video,
    },
  };

  const ItemIcon = () => {
    return typeOfItem === "group" ? (
      <FilterNoneIcon className="icon" />
    ) : typeOfItem === "video" ? (
      <PlayCircleIcon className="icon" />
    ) : null;
  };

  return (
    <MainContainer key={index}>
      <div
        className="img"
        style={{ backgroundImage: `url(${item.img})` }}
        onClick={() => {
          handleOpenModal();
        }}
      >
        <div className="overlay">
          <ItemIcon />
        </div>
      </div>
      <ModalGaleryView
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        content={contentForModal}
        text={item.description[currentLanguage]}
      />
    </MainContainer>
  );
};
export default GaleryItem;
