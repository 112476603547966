import styled from "styled-components";
import layout from "../../../../common/layout";

export const MainContainer = styled.div`
  margin-top: 20px;
  margin-left: 20px;
  /* border: 1px solid lightgray; */
  display: flex;
  padding: 30px 10px 20px 10px;
  border-radius: 10px;
  transition: 0.3s;
  /* display: grid; */
  flex: 1;
  transition: 0.3s;
  background-color: ${layout.colors.grayBg};

  :hover {
    box-shadow: -0px -0px 10px lightgrey;
    transition: 0.3s;
  }
  form {
    display: flex;
    flex-direction: column;
    flex: 1;

    .itemContainer {
      display: flex;
      flex: 1.2;

      input {
        margin: 0px 10px 20px 10px;
        width: 100%;
        border-radius: 50px;
        padding: 0px 20px;
        border: 1px solid lightgray;
        font-family: "Inter", sans-serif;
      }
    }

    textarea {
      display: flex;
      flex: 2;
      margin: 0px 10px 10px 10px;
      border-radius: 20px;
      border: 1px solid lightgray;
      padding: 20px;
      font-family: "Inter", sans-serif;
    }

    button {
      font-size: 12px;
      text-decoration: none;
      color: ${layout.colors.grayBg};
      background-color: ${layout.colors.mainColor};
      padding: 11px 30px;
      border-radius: 50px;
      font-weight: 700;
      border: 2px solid ${layout.colors.mainColor};
      margin: 10px;
      cursor: pointer;
      transition: 0.3s;
    }
    button:hover {
      color: ${layout.colors.mainColor};
      background-color: ${layout.colors.grayBg};
      transition: 0.3s;
    }
  }

  @media only screen and (max-width: ${layout.screenResolutions.desktop}) {
    margin-left: 0px;
    padding: 30px 20px;
    form {
      .itemContainer {
        flex-direction: column;

        input {
          margin: 0px;
          margin-bottom: 20px;
          padding: 0px 20px;
          height: 45px;
          min-width: 84%;
          max-width: 84%;
        }
      }
      textarea {
        margin: 0px;
        min-height: 150px;
        height: 150px !important;
      }

      button {
        margin: 0px;
        margin-top: 20px;
        width: 100%;
        padding: 13px 30px;
      }
    }
  }
`;
