import styled from "styled-components";
import layout from "../../../../../../common/layout";

export const MainContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 50%;
  background-color: transparent;
  padding: 0;
  border: 0px solid transparent;

  .img {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
  }

  .overlay {
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;

    .description {
      color: white;
      text-align: justify;
      font-weight: 500;
      background-color: rgba(0, 0, 0, 0.4);
      padding: 20px;
      margin-top: -0px;
    }
  }

  video {
    width: 100%;
    height: 100%;
  }

  .videoText {
    background-color: rgba(0, 0, 0, 0.6);
    padding: 10px;
    color: white;
    margin-bottom: 0px;
    z-index: 100;
  }

  .carousel {
    /* height: auto; */
  }
  .carouselDescription {
    color: white;
    position: absolute;
    z-index: 100;
    padding: 20px;
    top: -20px;
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
  }

  @media only screen and (max-width: ${layout.screenResolutions.desktop}) {
    width: 95%;
  }
`;
