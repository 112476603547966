import styled from "styled-components";
import layout from "../../../../common/layout";

export const MainContainer = styled.nav`
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  /*  */
  .subContainer {
    display: flex;
    padding: ${layout.designGlobalRules.desktop.padding};
    padding-top: 20px;
    padding-bottom: 30px;
    @media only screen and (max-width: ${layout.screenResolutions.desktop}) {
      padding: ${layout.designGlobalRules.mobile.padding};
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
  .bgScrollTop {
    background-color: transparent;
    transition: 0.3s;
  }
  .bgScrollDown {
    background-color: ${layout.colors.mainColor};
    transition: 0.3s;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  flex: 0.1;
  .logo {
    width: 100px;
  }
  @media only screen and (max-width: ${layout.screenResolutions.desktop}) {
    flex: 1;
  }
`;

export const ItemsContainer = styled.div`
  display: flex;
  flex: 0.8;
  align-items: center;
  padding-left: 100px;

  li {
    margin-top: 10px;
    padding: 0px 30px;
    list-style: none;
    a {
      text-decoration: none;
      color: ${layout.colors.grayText};
    }
    font-weight: 600;
    font-size: 15.5px;
  }
  @media only screen and (max-width: ${layout.screenResolutions.desktop}) {
    display: none;
  }
`;

export const SideMenuContainer = styled.div`
  display: flex;
  flex: 0.1;
  align-items: center;
  justify-content: flex-end;
  button {
    border-radius: 50px;
    background-color: transparent;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    color: ${layout.colors.grayButton};
    cursor: pointer;
    border: 1px solid ${layout.colors.grayButton};
  }

  .desktop {
    display: flex;
  }
  .mobile {
    display: none;
  }

  @media only screen and (max-width: ${layout.screenResolutions.desktop}) {
    flex: 1;
    .desktop {
      display: none;
    }
    .mobile {
      display: flex;
    }
  }
`;
